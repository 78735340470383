// src/components/AppHeader/AppHeader.tsx
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import NavBar from './NavBar';
import { AuthContext } from '../../context/AuthContext'; // Ruta corregida

interface LinkItem {
    label: string;
    href?: string;
    subLinks?: { label: string; href: string; roles: string[] }[];
    roles?: string[];
}

interface ExternalLink {
    label: string;
    href: string;
    className?: string;
    roles?: string[];
}

interface AppHeaderProps {
    navLinks: LinkItem[];
    externalLinks: ExternalLink[];
    onSearch: (query: string) => void;
    isLoggedIn: boolean;
    onLogin?: () => void; // Hacer opcional onLogin
    onLogout: () => void;
    loading: boolean;
}

const AppHeader: React.FC<AppHeaderProps> = ({
    navLinks,
    externalLinks,
    onSearch,
    isLoggedIn,
    onLogin,
    onLogout,
    loading
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { userData } = useContext(AuthContext); // Consume el contexto

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Definir los scopes requeridos (puedes mover esto al Contexto si lo prefieres)
    const scopes = {
        identify: true,
        guilds: true,
        'guilds.members.read': true,
        rpc: false,
        rpc_notifications_read: false,
        email: true,
        connections: false,
        openid: false,
        messages_read: true,
        applications_builds_read: false
    };

    // Función para construir el scope en formato requerido por Discord
    const buildScope = () => {
        return Object.entries(scopes)
            .filter(([_, isActive]) => isActive)
            .map(([key]) => key.replace(/_/g, '.'))
            .join(' ');
    };

    const scope = buildScope();

    // Construir la URL de autorización de Discord
    const redirectUri = encodeURIComponent(process.env.REACT_APP_REDIRECT_URI_NIVIRUMCLUBWEB || '');
    const clientId = process.env.REACT_APP_CLIENT_IDNIVIRUMCLUBWEB || '';
    const discordAuthUrl = `https://discord.com/oauth2/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${encodeURIComponent(scope)}`;

    // Manejar el inicio de sesión redirigiendo a Discord
    const handleLogin = () => {
        window.location.href = discordAuthUrl;
    };

    // Obtener el guild_role del usuario y normalizarlo
    const guildRole: string = userData?.guild_role.trim().toUpperCase() || '';

    // Función para verificar si el usuario puede ver un enlace basado en guild_role
    const canViewLink = (roles?: string[]) => {
        if (loading) return false; // No mostrar enlaces durante la carga
        if (!roles || roles.length === 0) return false;
        // Normalizar roles para comparación
        const rolesNormalized = roles.map(role => role.trim().toUpperCase());
        return rolesNormalized.includes(guildRole);
    };

    // Filtrar navLinks y sus subLinks basados en guild_role
    const filteredNavLinks = navLinks
        .filter(link => canViewLink(link.roles))
        .map(link => {
            // Si tiene sublinks, filtrar también
            if (link.subLinks) {
                const filteredSubLinks = link.subLinks.filter(s => canViewLink(s.roles));
                return { ...link, subLinks: filteredSubLinks };
            }
            return link;
        });

    // Filtrar externalLinks basado en guild_role
    const filteredExternalLinks = externalLinks.filter(el => canViewLink(el.roles));

    return (
        <header className="fixed top-0 left-0 z-50 w-full bg-zinc-900 bg-opacity-50 shadow-lg">
            <div className="mx-auto flex h-20 items-center justify-between px-6 md:max-w-[1200px]">
                <Link to="/">
                    <Logo />
                </Link>
                <nav className="hidden md:flex items-center">
                    <div className="mx-auto md:max-w-[1200px] flex items-center">
                        {isLoggedIn && !loading ? (
                            <NavBar links={filteredNavLinks} />
                        ) : (
                            // Si no está logueado, no mostramos enlaces, o podríamos mostrar enlaces públicos si los hubiera
                            null
                        )}

                        {!loading && isLoggedIn ? (
                            <button
                                onClick={onLogout}
                                className="ml-4 bg-red-600 text-white py-2 px-4 rounded-lg hover:brightness-105 transition duration-300"
                            >
                                Logout
                            </button>
                        ) : !loading && (
                            <button
                                onClick={handleLogin} // Usar handleLogin en lugar de onLogin
                                className="ml-4 bg-[#5865f2] text-white py-2 px-4 rounded-lg hover:brightness-105 transition duration-300"
                            >
                                Login with Discord
                            </button>
                        )}
                    </div>
                </nav>
                <div className="md:hidden flex items-center">
                    <button onClick={toggleMenu} className="text-white focus:outline-none">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor"
                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>
                </div>
            </div>

            {isMenuOpen && !loading && (
                <nav className="md:hidden bg-zinc-700">
                    {isLoggedIn ? (
                        <NavBar links={filteredNavLinks} />
                    ) : null}

                    {!loading && isLoggedIn ? (
                        <button
                            onClick={onLogout}
                            className="block mt-4 bg-red-600 text-white py-2 px-4 rounded-lg text-center hover:brightness-105 transition duration-300"
                        >
                            Logout
                        </button>
                    ) : !loading && (
                        <button
                            onClick={handleLogin} // Usar handleLogin en lugar de onLogin
                            className="block mt-4 bg-[#5865f2] text-white py-2 px-4 rounded-lg text-center hover:brightness-105 transition duration-300"
                        >
                            Login with Discord
                        </button>
                    )}
                </nav>
            )}
        </header>
    );

};

export default AppHeader;
