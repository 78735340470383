// src/components/App/ProtectedRoute.tsx
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

interface ProtectedRouteProps {
    requiredRoles?: string[];
    children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ requiredRoles = [], children }) => {
    const { isLoggedIn, userData, loading } = useContext(AuthContext);


    if (loading) {
        return <div className="loader">Cargando...</div>;
    }

    if (!isLoggedIn || !userData) {
        return <Navigate to="/" replace />;
    }

    if (requiredRoles.length > 0) {
        const userRoleNormalized = userData.guild_role.trim().toUpperCase();
        const rolesNormalized = requiredRoles.map(role => role.trim().toUpperCase());

        if (!rolesNormalized.includes(userRoleNormalized)) {
            console.log(`ProtectedRoute - Redirigiendo a / porque el rol ${userData.guild_role} no está en ${requiredRoles}`);
            return <Navigate to="/" replace />;
        }
    }

    return <>{children}</>;
};

export default ProtectedRoute;
