// src/components/App/AppContent.tsx
import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import AppHeader from './../AppHeader/AppHeader';
import ContentWrapper from './../ContentWrapper/ContentWrapper';
import GuildCalendar from '../ContentWrapper/Calendar/GuildCalendar';
import Profile from './../ContentWrapper/Profile/Profile';
import GuildManagement from '../ContentWrapper/Management/GuildManagement';
import ProtectedRoute from './ProtectedRoute';
import DiscordCallback from './DiscordCallback';
import { AuthContext } from '../../context/AuthContext'; // Ruta corregida

const AppContent: React.FC = () => {
    const { isLoggedIn, loading, error, logout, login } = useContext(AuthContext);

    // Definir los enlaces de navegación y externos
    const navLinks = [
        {
            label: 'Gestión',
            roles: ['LIDER', 'GERENTE SENIOR'],
            subLinks: [
                { label: 'Escuadrón', href: '/SquadronManagement', roles: ['LIDER', 'GERENTE SENIOR'] },
                { label: 'Gerencia', href: '/GuildManagement', roles: ['LIDER', 'GERENTE SENIOR'] }
            ]
        },
        {
            label: 'Calendario',
            roles: ['LIDER', 'GERENTE SENIOR'],
            subLinks: [
                { label: 'Escuadrón', href: '/SquadronCalendar', roles: ['LIDER', 'GERENTE SENIOR', 'GERENTE', 'MIEMBRO', 'ALPHA 2'] },
                { label: 'Guild', href: '/GuildCalendar', roles: ['LIDER', 'GERENTE SENIOR', 'GERENTE', 'MIEMBRO', 'ALPHA 2'] }
            ]
        },
        {
            label: 'DKPs',
            roles: ['LIDER', 'GERENTE SENIOR'],
            subLinks: [
                { label: 'Pujatorio', href: '/Pujatorio', roles: ['LIDER', 'GERENTE SENIOR', 'GERENTE', 'MIEMBRO', 'ALPHA 2'] },
                { label: 'My DKPs', href: '/MyDKPs', roles: ['LIDER', 'GERENTE SENIOR', 'GERENTE', 'MIEMBRO', 'ALPHA 2'] }
            ]
        },
        {
            label: 'Profile',
            href: '/Profile',
            roles: ['LIDER', 'GERENTE SENIOR', 'GERENTE', 'MIEMBRO', 'ALPHA 2']
        }
    ];

    const externalLinks = [
        {
            label: 'Discord',
            href: 'https://discord.gg',
            className: 'bg-[#5865f2] text-white hover:brightness-105',
            roles: ['LIDER', 'GERENTE SENIOR']
        },
    ];

    const handleSearch = (query: string) => {
        console.log('Search:', query);
    };

    return (
        <div className="bg-[#1e1e1e] text-white min-h-screen overflow-x-hidden relative">
            {loading && (
                <div className="absolute inset-0 bg-gray-900 bg-opacity-70 flex items-center justify-center z-50">
                    <div className="loader border-t-4 border-b-4 border-white w-12 h-12 rounded-full animate-spin"></div>
                </div>
            )}
            {error && (
                <div className="absolute inset-0 bg-gray-900 bg-opacity-70 flex flex-col items-center justify-center z-50 text-white">
                    <div className="border-t-4 border-b-4 border-yellow-500 w-12 h-12 rounded-full animate-spin mb-4"></div>
                    <p>{error}</p>
                    <button
                        className="mt-4 bg-yellow-500 text-white px-4 py-2 rounded"
                        onClick={() => { /* Aquí podrías intentar refrescar los datos o cerrar sesión */ }}
                    >
                        Cerrar
                    </button>
                </div>
            )}

            <AppHeader
                navLinks={navLinks}
                externalLinks={externalLinks}
                onSearch={handleSearch}
                isLoggedIn={isLoggedIn}
                onLogout={logout}
                onLogin={login}
                loading={loading}
            />

            <div className="bg-[#1e1e1e]">
                <main>
                    <div>
                        {!loading && !error && (
                            <Routes>
                                <Route path="/" element={<ContentWrapper />} />

                                {/* Ruta para manejar el callback de Discord */}
                                <Route path="/DiscordCallback" element={<DiscordCallback />} />

                                {/* Rutas Protegidas */}
                                <Route path="/Profile" element={
                                    <ProtectedRoute requiredRoles={['LIDER', 'GERENTE SENIOR', 'GERENTE', 'MIEMBRO', 'ALPHA 2']}>
                                        <Profile />
                                    </ProtectedRoute>
                                } />

                                <Route path="/SquadronManagement" element={
                                    <ProtectedRoute requiredRoles={['LIDER', 'GERENTE SENIOR']}>
                                        <GuildManagement />
                                    </ProtectedRoute>
                                } />
                                <Route path="/GuildManagement" element={
                                    <ProtectedRoute requiredRoles={['LIDER', 'GERENTE SENIOR']}>
                                        <GuildManagement />
                                    </ProtectedRoute>
                                } />
                                <Route path="/SquadronCalendar" element={
                                    <ProtectedRoute requiredRoles={['LIDER', 'GERENTE SENIOR', 'GERENTE', 'MIEMBRO', 'ALPHA 2']}>
                                        <GuildCalendar />
                                    </ProtectedRoute>
                                } />
                                <Route path="/GuildCalendar" element={
                                    <ProtectedRoute requiredRoles={['LIDER', 'GERENTE SENIOR', 'GERENTE', 'MIEMBRO', 'ALPHA 2']}>
                                        <GuildCalendar />
                                    </ProtectedRoute>
                                } />
                                <Route path="/Pujatorio" element={
                                    <ProtectedRoute requiredRoles={['LIDER', 'GERENTE SENIOR', 'GERENTE', 'MIEMBRO', 'ALPHA 2']}>
                                        <Profile />
                                    </ProtectedRoute>
                                } />
                                <Route path="/MyDKPs" element={
                                    <ProtectedRoute requiredRoles={['LIDER', 'GERENTE SENIOR', 'GERENTE', 'MIEMBRO', 'ALPHA 2']}>
                                        <GuildCalendar />
                                    </ProtectedRoute>
                                } />
                            </Routes>
                        )}
                    </div>
                </main>
            </div>
        </div>
    );
};

export default AppContent;
