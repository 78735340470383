// src/App.tsx
import React from 'react';
import AppContent from './AppContent';
import { AuthProvider } from '../../context/AuthContext';
import { BrowserRouter as Router } from 'react-router-dom';

const App: React.FC = () => {
    return (
        <Router>
            <AuthProvider>
                <AppContent />
            </AuthProvider>
        </Router>
    );
};

export default App;
