// src/context/AuthContext.tsx
import React, { createContext, useState, useEffect, ReactNode } from 'react';
import axios from '../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';

export interface UserData {
    member_id: number;
    discord_id: string;
    username: string;
    email: string;
    guild_role: string;
    icon?: string;
    // Añade otros campos según sea necesario
}

interface AuthContextType {
    isLoggedIn: boolean;
    userData: UserData | null;
    loading: boolean;
    error: string | null;
    login: () => void;
    logout: () => void;
    setUserData: React.Dispatch<React.SetStateAction<UserData | null>>;
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
    setError: React.Dispatch<React.SetStateAction<string | null>>;
}

export const AuthContext = createContext<AuthContextType>({
    isLoggedIn: false,
    userData: null,
    loading: true, // Inicializar como true
    error: null,
    login: () => { },
    logout: () => { },
    setUserData: () => { },
    setIsLoggedIn: () => { },
    setError: () => { }
});

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [userData, setUserData] = useState<UserData | null>(null);
    const [loading, setLoading] = useState<boolean>(true); // Inicializar como true
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    // Verificar la autenticación al montar el componente
    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('authToken');
            if (token) {
                setIsLoggedIn(true);
                try {
                    const response = await axios.get<UserData>('/api/members/me', {
                        withCredentials: true // Esto asegura que se envíen cookies
                    });
                    if (response.status === 200) {
                        setUserData(response.data);
                        console.log('Datos del usuario obtenidos:', response.data);
                    }
                } catch (err) {
                    console.error('Error al obtener los datos del usuario:', err);
                    setError('Hubo un error al obtener tus datos. Por favor, intenta nuevamente.');
                    setIsLoggedIn(false);
                    setUserData(null);
                    localStorage.removeItem('authToken');
                } finally {
                    setLoading(false); // Finalizar carga
                }
            } else {
                setLoading(false); // Finalizar carga si no hay token
            }
        };

        checkAuth();
    }, []);

    const login = () => {
        const scopes = {
            identify: true,
            guilds: true,
            'guilds.members.read': true,
            rpc: false,
            rpc_notifications_read: false,
            email: true,
            connections: false,
            openid: false,
            messages_read: true,
            applications_builds_read: false
        };

        const buildScope = () => {
            return Object.entries(scopes)
                .filter(([_, isActive]) => isActive)
                .map(([key]) => key.replace(/_/g, '.'))
                .join(' ');
        };

        const scope = buildScope();
        const redirectUri = encodeURIComponent(process.env.REACT_APP_REDIRECT_URI_NIVIRUMCLUBWEB || '');
        const clientId = process.env.REACT_APP_CLIENT_IDNIVIRUMCLUBWEB || '';
        const discordAuthUrl = `https://discord.com/oauth2/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${encodeURIComponent(scope)}`;

        window.location.href = discordAuthUrl;
    };

    const logout = () => {
        setIsLoggedIn(false);
        setUserData(null);
        setError(null);
        localStorage.removeItem('authToken');
        navigate('/');
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, userData, loading, error, login, logout, setUserData, setIsLoggedIn, setError }}>
            {children}
        </AuthContext.Provider>
    );
};
