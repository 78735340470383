// src/components/App/DiscordCallback.tsx
import React, { useEffect, useRef, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosConfig'; // Usa la instancia configurada de Axios
import { AuthContext, UserData } from '../../context/AuthContext'; // Ruta corregida

const DiscordCallback: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isMounted = useRef(false); // Ref para rastrear si el efecto ya se ejecutó
    const { setIsLoggedIn, setUserData, setError } = useContext(AuthContext); // Añadido setError

    useEffect(() => {
        if (isMounted.current) return; // Si ya se ejecutó, salir
        isMounted.current = true; // Marcar como ejecutado

        const handleDiscordCallback = async () => {
            const params = new URLSearchParams(location.search);
            const code = params.get('code');

            if (!code) {
                console.error('No se encontró el código de autorización en la URL');
                setError('Código de autorización faltante. Por favor, intenta nuevamente.');
                return;
            }

            try {
                console.log('Enviando código al backend para intercambiar tokens y establecer cookies');
                console.log('axios get baseURL:', axiosInstance.defaults.baseURL);
                console.log('axios get baseURL:',  process.env.BASE_URL);
                // Enviar el código al backend para intercambiar tokens y establecer cookies
                const response = await axiosInstance.get(`/api/members/auth/discord/callback?code=${code}`);

                if (response.status === 200) {
                    console.log('Código intercambiado exitosamente');
                    // Obtener los datos del usuario desde el backend
                    const userResponse = await axiosInstance.get<UserData>('/api/members/me');

                    if (userResponse.status === 200) {
                        setUserData(userResponse.data);
                        setIsLoggedIn(true);
                        navigate('/Profile');
                        console.log('Usuario autenticado con éxito:', userResponse.data);
                        // Guardar el token en localStorage para persistencia
                        localStorage.setItem('authToken', 'true'); // Reemplaza 'true' con el token real si lo tienes
                    } else {
                        console.error('Error al obtener los datos del usuario:', userResponse.status);
                        setError('Error al obtener los datos del usuario. Por favor, intenta nuevamente.');
                    }
                } else {
                    console.error('Error en la respuesta del backend:', response.status);
                    setError('Error durante la autenticación. Por favor, intenta nuevamente.');
                }
            } catch (err) {
                console.error('Error durante la autorización:', err);
                setError('Error durante la autorización. Por favor, intenta nuevamente.');
            }
        };

        handleDiscordCallback();
    }, [location.search, navigate, setIsLoggedIn, setUserData, setError]);

    return <div>Procesando autenticación...</div>;
};

export default DiscordCallback;
