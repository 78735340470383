import React, { useEffect, useState, useRef as useReactRef } from 'react';

const teamMembers = [
  { name: 'Sherkus', role: 'LÍDER', img: '/Information/logosherkus.png' },
  { name: 'Crosis', role: 'Gerente Senior', img: '/Information/logocrosis.jpg' },
  { name: 'Anaxágoras', role: 'Gerente Senior', img: '/Information/logoanaxagoras.png' },
  { name: 'Sark', role: 'Gerente Senior', img: '/Information/logosark.png' },
  { name: 'Shinaso', role: 'Gerente Senior', img: '/Information/logoshinaso.png' },
  { name: 'Vyro', role: 'Gerente Senior', img: '/Information/logovyro.png' },
  { name: 'Antiquericard', role: 'Gerente Senior', img: '/Information/logoantiquericard.png' },
  { name: 'Arnelas', role: 'Gerente', img: '/Information/logoarnelas.png' },
  { name: 'AllucardCD', role: 'Gerente', img: '/Information/logoallucardcd.png' },
  { name: 'Lapiz', role: 'Gerente', img: '/Information/logolapiz.jpg' },
  { name: 'Risketos', role: 'Gerente', img: '/Information/logorisketos.jpg' },
  { name: 'Cheeko', role: 'Gerente', img: '/Information/logocheeko.png' },
  { name: 'EXTrancos', role: 'Gerente', img: '/Information/logoextrancos.jpg' },
  { name: 'Ragnar', role: 'Gerente', img: '/Information/logoragnar.jpg' }
];

interface UseIsVisibleProps {
  ref: React.RefObject<HTMLElement>;
}

interface IntersectionObserverEntry {
  isIntersecting: boolean;
}

function useRef<T extends HTMLElement = HTMLDivElement>(): React.RefObject<T> {
  return useReactRef<T>(null);
}

export function useIsVisible(ref: UseIsVisibleProps['ref']): boolean {
  const [isIntersecting, setIntersecting] = useState<boolean>(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]: IntersectionObserverEntry[]) => {
      setIntersecting(entry.isIntersecting);
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}


const ContentWrapper: React.FC = () => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState<{ [key: number]: boolean }>({});
  const ref1 = useRef<HTMLDivElement>();
  const isVisible1 = useIsVisible(ref1);
  const ref2 = useRef<HTMLDivElement>();
  const isVisible2 = useIsVisible(ref2);

  const toggleAccordion = (index: number) => {
    setIsAccordionOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  return (
    <div className="">
      <div className="relative overflow-hidden">
        <img
          src="/backgroundNivirum.webp"
          alt="bg"
          className="w-full h-full object-cover animate-zoomOut"
          onLoad={handleImageLoad}
        />
        <div className="absolute inset-0 bg-[#1e1e1e] bg-opacity-30"></div>
        <div className={`absolute inset-0 flex items-center justify-center transition-opacity duration-1000 ${isImageLoaded ? 'opacity-100' : 'opacity-0'}`}>
          <div className="rounded-lg max-w-lg w-full">
            <img
              src="/Information/banner-con-letras.png"
              alt="Banner NIVIRUM CLUB"
              className={`mx-auto max-w-full h-auto transition-opacity duration-1000 ${isImageLoaded ? 'opacity-100' : 'opacity-0'}`}
              onLoad={handleImageLoad}
            />
            <div className="p-12"></div>
            <div className="p-8 bg-[#1c1c1c] bg-opacity-50 rounded-lg max-w-lg w-full">
              <p className="text-white text-center font-bold text-lg">
                Conquista Verra junto a una comunidad organizada y apasionada por el PvP competitivo. En <em>Ashes of Creation</em>, tu habilidad y dedicación son la clave para marcar la diferencia. Si buscas una experiencia única y desafiante, ¡esta es tu oportunidad de unirte a nuestra causa!
              </p>
              <div className="flex justify-center mt-4">
                <a href="https://discord.gg/sE7MDdPT" className="inline-flex items-center px-6 py-2 text-white bg-blue-600 rounded hover:bg-blue-700" target="_blank" rel="noopener noreferrer">
                  Únete al Discord
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contenedor principal */}

      <div className="bg-[#1c1c1c] p-6 rounded-lg text-white max-w-[95%] sm:max-w-[85%] lg:max-w-[75%] mx-auto bg-opacity-30 shadow-lg lg:justify-center">
        <div ref={ref1} className={`transition-opacity ease-in duration-1000 ${isVisible1 ? "opacity-100" : "opacity-0"}`}>
          <div className="flex-1 ml-2.5 animate-fade-up animate-once animate-ease-in-out">
            <h2 className="text-2xl text-[#ffdd57] mb-4 text-center">Equipo de Administración</h2>
            <div className="team-cards flex flex-wrap gap-5 justify-center">
              {teamMembers.map((member, index) => (
                <div className="card bg-[#1e1e1e] border-2 border-[#3d3d3d] rounded-lg p-5 text-center w-[150px] transition-transform duration-300 ease-in-out hover:scale-105 hover:border-[#ffc107]" key={index}>
                  <div className="flex justify-center items-center">
                    <img src={member.img} alt={`${member.name}'s avatar`} className="object-cover rounded-full w-[70px] h-[70px]" />
                  </div>
                  <h3 className="text-xl text-white mt-2.5">{member.name}</h3>
                  <p className="text-lg text-[#ccc] mt-1.25">{member.role}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-between items-start gap-2 mt-[2%]">
          <div ref={ref2} className={`transition-opacity ease-in duration-1000 ${isVisible2 ? "opacity-100" : "opacity-0"}`}>
            {/* Sección de Historia */}
            <div className="flex-1 mr-2.5">
              {/* Accordion Item 1 */}
              <div className="border-b border-slate-200">
                <button onClick={() => toggleAccordion(1)} className="w-full flex justify-between items-center py-5 text-slate-800">
                  <span className="text-2xl text-[#ffdd57] mb-4 text-center">Introducción</span>
                  <span className={`text-[#ffdd57] transition-transform duration-300 ${isAccordionOpen[1] ? 'rotate-180' : ''}`}>
                    {isAccordionOpen[1] ? (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
                        <path d="M3.75 7.25a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5h-8.5Z" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
                        <path d="M8.75 3.75a.75.75 0 0 0-1.5 0v3.5h-3.5a.75.75 0 0 0 0 1.5h3.5v3.5a.75.75 0 0 0 1.5 0v-3.5h3.5a.75.75 0 0 0 0-1.5h-3.5v-3.5Z" />
                      </svg>
                    )}
                  </span>
                </button>
                <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isAccordionOpen[1] ? 'max-h-screen' : 'max-h-0'}`}>
                  <div className="pb-5 text-sm text-slate-500">
                    <div className="secondary-team-section flex-1 mr-2.5">
                      <p className="text-justify text-[#ddd] mb-4">Hola. No importa si estás leyendo esto sin tener intenciones de ingresar, o que seas un LIDER de otro clan que lo lee por curiosidad o aprendizaje. Estás aquí y te damos las gracias por el interés.</p>
                      <p className="text-justify text-[#ddd] mb-4">El objetivo de este documento es la transparencia (dentro de lo razonable claro), algo similar a lo que ha hecho Intrepid con el desarrollo de Ashes of Creation. Es la forma más informal y fácil para que sepas quiénes somos y cómo funcionamos. Cualificaciones, enfoque, mentalidad, requisitos. Orígenes. Intentaremos explayarnos lo máximo posible sin salirnos de la prudencia, y dejarlo a medio camino entre “currículum” y “guía de liderazgo“. Vamos allá.</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Accordion Item 2 */}
              <div className="border-b border-slate-200">
                <button onClick={() => toggleAccordion(2)} className="w-full flex justify-between items-center py-5 text-slate-800">
                  <span className="text-2xl text-[#ffdd57] mb-4 text-center">Historia de NIVIRUM CLUB [NIV] [NC]</span>
                  <span className={`text-[#ffdd57] transition-transform duration-300 ${isAccordionOpen[2] ? 'rotate-180' : ''}`}>
                    {isAccordionOpen[2] ? (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
                        <path d="M3.75 7.25a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5h-8.5Z" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
                        <path d="M8.75 3.75a.75.75 0 0 0-1.5 0v3.5h-3.5a.75.75 0 0 0 0 1.5h3.5v3.5a.75.75 0 0 0 1.5 0v-3.5h3.5a.75.75 0 0 0 0-1.5h-3.5v-3.5Z" />
                      </svg>
                    )}
                  </span>
                </button>
                <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isAccordionOpen[2] ? 'max-h-screen' : 'max-h-0'}`}>
                  <div className="pb-5 text-sm text-slate-500">
                    <div className="secondary-team-section flex-1 mr-2.5">
                      <p className="text-justify text-[#ddd] mb-4">Este clan no se hubiese concebido sin la promesa de un MMORPG como AoC en el horizonte.</p>
                      <p className="text-justify text-[#ddd] mb-4">Algunos de nosotros llevamos mucho tiempo en segundo plano, en clanes, otros jugando en solitario, y algún otro liderando temporalmente un clan en un juego que no le apasionaba del todo… Adormecidos, aletargados. A la espera de algo que nos sacase del soponcio que nos provocaban los MMOs y survivals que se lanzaban en aquel momento.</p>
                      <p className="text-justify text-[#ddd] mb-4">Una mañana a finales de mayo de 7, uno de nosotros –no recordamos quién fue–, vio un curioso proyecto en Kickstarter que le llamó la atención y se lo enseñó al resto. En aquel entonces Ashes of Creation no era más que unas ideas pegadas con chicle. Sin embargo, entre que poseía alguna característica atrayente, y por las circunstancias especiales de entonces en plena sequía de juegos (ingenua desesperación), decidimos seguir su progreso. Uno incluso lo apoyó económicamente. No fue de extrañar que cuando se anunció la Alpha 1 nos impusiésemos la asistencia obligatoria. Y así ocurrió.</p>
                      <p className="text-justify text-[#ddd] mb-4">Cuando la primera fase de pruebas acabó fuimos conscientes del potencial del juego y nos motivamos sobremanera. Tanto fue así como para que volviese a picarnos el gusanillo de crear algo más que un clan hardcore y competitivo, uno que estuviese a la altura de clanes top extranjeros de máximo nivel. Muy ambicioso. Invitamos a otros jugadores exigentes que, como alguno de nosotros, también poseían experiencia en clanes y alianzas top de EU y NA. Y así, la renovación interna y externa comenzó.</p>
                      <p className="text-justify text-[#ddd] mb-4">Gracias a los conocimientos que algunos albergábamos en este tipo de gremios tier 1, ya poseíamos una gran y robusta base con la que empezar, pero no lo creíamos suficiente. Durante meses estudiamos grupalmente a otros gremios e instruyéndonos en organización de grupos, psicología conductista y motivacional, estrategias multi ámbito, inteligencia y contrainteligencia, programación universal, publicidad, economía, política, logística empresarial y militar, etc. etc.</p>
                      <p className="text-justify text-[#ddd] mb-4">Sí… En efecto, nos vinimos muy arriba 😊 Y así nació el clan que es a día de hoy.</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Accordion Item 3 */}
              <div className="border-b border-slate-200">
                <button onClick={() => toggleAccordion(3)} className="w-full flex justify-between items-center py-5 text-slate-800">
                  <span className="text-2xl text-[#ffdd57] mb-4 text-center">¿Qué ofrece NIV?</span>
                  <span className={`text-[#ffdd57] transition-transform duration-300 ${isAccordionOpen[3] ? 'rotate-180' : ''}`}>
                    {isAccordionOpen[2] ? (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
                        <path d="M3.75 7.25a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5h-8.5Z" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
                        <path d="M8.75 3.75a.75.75 0 0 0-1.5 0v3.5h-3.5a.75.75 0 0 0 0 1.5h3.5v3.5a.75.75 0 0 0 1.5 0v-3.5h3.5a.75.75 0 0 0 0-1.5h-3.5v-3.5Z" />
                      </svg>
                    )}
                  </span>
                </button>
                <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isAccordionOpen[3] ? 'max-h-screen' : 'max-h-0'}`}>
                  <div className="pb-5 text-sm text-slate-500">
                    <div className="secondary-team-section flex-1 mr-2.5">
                      <p className="text-justify text-[#ddd] mb-4"><strong className="text-[#ffdd57]">Enfoque en Competencia PvP y PvE:</strong> Nivirum Club es un clan europeo centrado en dominar batallas y asedios PvP a gran escala, participando también en PvE para preparar a sus miembros.</p>
                      <p className="text-justify text-[#ddd] mb-4"><strong className="text-[#ffdd57]">Liderazgo Colaborativo:</strong> Todos los miembros participan en el proceso de liderazgo, con una estructura que permite una toma de decisiones ágil.</p>
                      <p className="text-justify text-[#ddd] mb-4"><strong className="text-[#ffdd57]">Comunidad y Mentoría:</strong> Fomentamos un ambiente de camaradería y ofrecemos mentoría para ayudar a los nuevos miembros a integrarse y mejorar sus habilidades.</p>
                      <p className="text-justify text-[#ddd] mb-4"><strong className="text-[#ffdd57]">Recursos y Alianzas:</strong> Proporcionamos guías y recursos valiosos, y formamos alianzas estratégicas con otros clanes para fortalecer nuestra posición en PvP.</p>
                      <p className="text-justify text-[#ddd] mb-4"><strong className="text-[#ffdd57]">Política de Cero Toxicidad:</strong> Mantenemos una estricta política contra el comportamiento tóxico, promoviendo un entorno de respeto y cooperación.</p>
                      <p className="text-justify text-[#ddd] mb-4"><strong className="text-[#ffdd57]">Integración de Nuevos Miembros:</strong> Facilitamos la adaptación de los nuevos con mentoría y recursos compartidos, asegurando su rápido desarrollo dentro del clan.</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Accordion Item 4 */}
              <div className="border-b border-slate-200">
                <button onClick={() => toggleAccordion(4)} className="w-full flex justify-between items-center py-5 text-slate-800">
                  <span className="text-2xl text-[#ffdd57] mb-4 text-center">¿Qué objetivos tiene NIV?</span>
                  <span className={`text-[#ffdd57] transition-transform duration-300 ${isAccordionOpen[4] ? 'rotate-180' : ''}`}>
                    {isAccordionOpen[2] ? (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
                        <path d="M3.75 7.25a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5h-8.5Z" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
                        <path d="M8.75 3.75a.75.75 0 0 0-1.5 0v3.5h-3.5a.75.75 0 0 0 0 1.5h3.5v3.5a.75.75 0 0 0 1.5 0v-3.5h3.5a.75.75 0 0 0 0-1.5h-3.5v-3.5Z" />
                      </svg>
                    )}
                  </span>
                </button>
                <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isAccordionOpen[4] ? 'max-h-screen' : 'max-h-0'}`}>
                  <div className="pb-5 text-sm text-slate-500">
                    <div className="secondary-team-section flex-1 mr-2.5">
                      <p className="text-justify text-[#ddd] mb-4"><strong className="text-[#ffdd57]">Dominio en PvP:</strong> Nivirum Club busca dominar en los campos de batalla y asedios en Ashes of Creation.</p>
                      <p className="text-justify text-[#ddd] mb-4"><strong className="text-[#ffdd57]">Competencia de Alto Nivel:</strong> Sobresalir enfrentándose a otros clanes con estrategia y coordinación.</p>
                      <p className="text-justify text-[#ddd] mb-4"><strong className="text-[#ffdd57]">Crecimiento y Mejora Constante:</strong> Entrenamientos regulares y participación en eventos desafiantes.</p>
                      <p className="text-justify text-[#ddd] mb-4"><strong className="text-[#ffdd57]">Liderazgo en la Comunidad Hispana:</strong> Posicionarse como un referente en la comunidad hispana.</p>
                      <p className="text-justify text-[#ddd] mb-4"><strong className="text-[#ffdd57]">Fomentar una Comunidad Saludable:</strong> Promover un ambiente de camaradería y respeto.</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Accordion Item 5 */}
              <div className="border-b border-slate-200">
                <button onClick={() => toggleAccordion(5)} className="w-full flex justify-between items-center py-5 text-slate-800">
                  <span className="text-2xl text-[#ffdd57] mb-4 text-center">Requisitos de admisión</span>
                  <span className={`text-[#ffdd57] transition-transform duration-300 ${isAccordionOpen[5] ? 'rotate-180' : ''}`}>
                    {isAccordionOpen[2] ? (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
                        <path d="M3.75 7.25a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5h-8.5Z" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
                        <path d="M8.75 3.75a.75.75 0 0 0-1.5 0v3.5h-3.5a.75.75 0 0 0 0 1.5h3.5v3.5a.75.75 0 0 0 1.5 0v-3.5h3.5a.75.75 0 0 0 0-1.5h-3.5v-3.5Z" />
                      </svg>
                    )}
                  </span>
                </button>
                <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isAccordionOpen[5] ? 'max-h-screen' : 'max-h-0'}`}>
                  <div className="pb-5 text-sm text-slate-500">
                    <div className="secondary-team-section flex-1 mr-2.5">
                      <p className="text-justify text-[#ddd] mb-4"><strong className="text-[#ffdd57]">Edad:</strong> Ser mayor de 18 años.</p>
                      <p className="text-justify text-[#ddd] mb-4"><strong className="text-[#ffdd57]">Compromiso:</strong> Participar en eventos y actividades según tu horario.</p>
                      <p className="text-justify text-[#ddd] mb-4"><strong className="text-[#ffdd57]">Habilidad:</strong> Capacidad de participar en PvP de alto nivel y mejorar continuamente.</p>
                      <p className="text-justify text-[#ddd] mb-4"><strong className="text-[#ffdd57]">Comunicación:</strong> Uso obligatorio de Discord durante actividades del clan.</p>
                      <p className="text-justify text-[#ddd] mb-4"><strong className="text-[#ffdd57]">Mentalidad:</strong> Alinearse con los valores del clan: respeto, cooperación y excelencia.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentWrapper;

